import { aiApiSlice } from "../../app/api/aiApiSlice";

export const documentApiSlice = aiApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserDocuments: build.query({
      query: () => "/documents",
      providesTags: () => ["Documents"],
    }),

    uploadDocuments: build.mutation({
      query: (files) => {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file);
        });

        console.log(formData);

        return {
          url: "/documents",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: () => ["Documents"],
    }),

    deleteDocuments: build.mutation({
      query: (fileNames) => ({
        url: "/documents",
        method: "DELETE",
        body: { fileNames },
      }),
      invalidatesTags: () => ["Documents"],
    }),

    // Retrieves all finished processes of a user
    getFinishedUserProcesses: build.query({
      query: () => "/processes/finished",
    }),
  }),
});

export const {
  useGetUserDocumentsQuery,
  useUploadDocumentsMutation,
  useDeleteDocumentsMutation,
} = documentApiSlice;
