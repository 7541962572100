import { useState, useEffect, useCallback } from "react";

const useDataFetcher = (url, transformFunction) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok!");
      }
      const data = await response.json();
      setData(transformFunction ? transformFunction(data) : data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url, setData, transformFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData, transformFunction]);

  return { data, loading, error };
};

export default useDataFetcher;
