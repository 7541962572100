import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

import CustomInput from "../Input/CustomInput";
import CountryCodeSelector from "../CountryCodeSelector/CountryCodeSelector";

import { useGetBionNameQuery } from "../../../features/bion/bionApiSlice";
import signupSchema from "../../../validations/signup";
import signupHalfSchema from "../../../validations/signupHalf";

import styles from "./Step1.module.css";

const Step1 = ({ bionId, data, onSubmit, onValidityChange }) => {
  // Initialize useForm hook to handle form state and validation
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(bionId ? signupSchema : signupHalfSchema),
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: data,
  });

  const [agreeTerms, setAgreeTerms] = useState(false);

  const { data: bion, isLoading } = useGetBionNameQuery(bionId);
  const bionName = bion?.bionName;

  useEffect(() => {
    // Call the onValidityChange function whenever the validity state changes
    onValidityChange(isValid && agreeTerms);
  }, [isValid, onValidityChange, agreeTerms]);

  // useEffect(() => {
  //   if (!bionId) {
  //     setValue("bionName", "test");
  //   }
  // }, []);

  useEffect(() => {
    // Set bionName input with the name of the BION only if the name exists
    if (bionName) {
      setValue("bionName", bionName);
    }
  }, [setValue, bionName]);

  return (
    <div className={styles["step"]}>
      <h3 className={styles["step-info"]}>
        Enter your basic details to create your account. It will only take a few
        moments.
      </h3>

      <form
        id="form-1"
        onSubmit={handleSubmit(onSubmit)}
        className={styles["form"]}
      >
        {bionId && (
          <CustomInput
            label="BION"
            id="bionName"
            register={register}
            errors={errors}
            watch={watch}
            isLoading={isLoading}
            type="text"
            placeholder="BION Name"
            autoComplete="off"
            disabled={isLoading}
          />
        )}

        <CustomInput
          label="Username"
          id="userName"
          register={register}
          errors={errors}
          watch={watch}
          type="text"
          placeholder="Your Username"
          autoComplete="off"
        />

        <CustomInput
          label="Alias"
          id="alias"
          register={register}
          errors={errors}
          watch={watch}
          type="text"
          placeholder="Nickname (visible to others)"
          autoComplete="off"
        />

        <div className={styles["phone-number__row"]}>
          <div style={{ marginBottom: errors["phone"] ? "20px" : "0px" }}>
            <CountryCodeSelector
              label="Country code"
              id="countryCode"
              control={control}
            />
          </div>
          <div>
            <CustomInput
              label="Phone number"
              id="phone"
              register={register}
              errors={errors}
              watch={watch}
              type="tel"
              placeholder="Enter your phone number"
              autoComplete="off"
            />
          </div>
        </div>

        <CustomInput
          label="Email"
          id="email"
          register={register}
          errors={errors}
          watch={watch}
          type="email"
          placeholder="johndoe@yahoo.com"
          autoComplete="off"
        />

        <CustomInput
          label="Password"
          id="password"
          register={register}
          errors={errors}
          watch={watch}
          type="password"
          placeholder="Pick a strong password"
          autoComplete="off"
        />

        <div className={styles["privacy-policy"]}>
          <input
            type="checkbox"
            checked={agreeTerms}
            onChange={() => setAgreeTerms((prevValue) => !prevValue)}
          />
          <p>
            I have read and agree to Cultzyme's{" "}
            <Link to="/privacy-policy" style={{ color: "#0095ff" }}>
              Privacy Policy
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Step1;
