import { useCallback } from "react";

import Select from "react-select";
import { Controller } from "react-hook-form";

import useDataFetcher from "../../../hooks/use-dataFetcher";

import styles from "./CountryCodeSelector.module.css";

const CountryCodeSelector = ({ label, control, validationLabel }) => {
  const customTransformation = useCallback((data) => {
    return data.map((item) => ({
      value: item.dial_code,
      label: item.dial_code,
      name: item.name,
      imageSrc: `https://flagsapi.com/${item.code}/flat/32.png`,
    }));
  }, []);

  const { data: options, loading } = useDataFetcher(
    "/data/countryCodes.json",
    customTransformation
  );

  // Custom function to render HTML for each option label
  const getOptionLabel = (option) => (
    <div className={styles["selector__label"]}>
      <img
        src={option.imageSrc}
        alt={`(${option.label})`}
        style={{ width: "20px", marginRight: "8px" }}
      />
      {option.label}
    </div>
  );

  // Custom search logic
  const customFilter = (option, searchText) => {
    return (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.includes(searchText)
    );
  };

  return (
    <div>
      <label className={styles["label"]}>{label}</label>
      <Controller
        name="countryCode"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value={
              options
                ? options.find((option) => option.value === field.value)
                : ""
            }
            onChange={(selectedOption) => field.onChange(selectedOption.value)}
            options={options}
            classNames={{
              control: (state) => styles["control"],
              menu: () => styles["menu"],
              input: () => styles["input"],
            }}
            getOptionLabel={getOptionLabel}
            isSearchable={true}
            filterOption={customFilter}
            isLoading={loading}
          />
        )}
      />
    </div>
  );
};

export default CountryCodeSelector;
