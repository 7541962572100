import { Outlet } from "react-router-dom";

import Navigation from "../../Navigation/Navigation";
import GradientAnimation from "../../UI/GradientAnimation/GradientAnimation";

import styles from "./AppWrapper.module.css";

const AppWrapper = () => {
  return (
    <div className={styles["app-wrapper"]}>
      {/* Background Animation */}
      <GradientAnimation
        overlayStyles={{
          background: "rgba(16, 16, 16, 0.5)",
          backdropFilter: " blur(7px)",
        }}
      />

      {/* Navigation | Left Side */}
      <Navigation />

      {/* Main Area | Right Side */}
      <main className={styles["main"]}>
        <Outlet />
      </main>
    </div>
  );
};

export default AppWrapper;
