import { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Select from "react-select";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";

import LoadingText from "../../UI/LoadingText/LoadingText";

import { useCreateProcessMutation } from "../../../features/process/processApiSlice";
import { useUpdateCalibrationMutation } from "../../../features/bion/bionApiSlice";

import { SiFuturelearn as BionicIcon } from "react-icons/si";
import { FaCopy as ReplicationIcon } from "react-icons/fa";
import { IoCreateSharp as CreationIcon } from "react-icons/io5";
import { CgCalibrate as CalibrationIcon } from "react-icons/cg";

import BionImg from "../../../assets/images/bion.png";

import styles from "./CreateProcess.module.css";
import CalibrateModal from "../CalibrateModal/CalibrateModal";

const CreateProcess = ({ bion }) => {
  const [creationIsOpen, setCreationIsOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);

  const [calibrationIsOpen, setCalibrationIsOpen] = useState(
    bion?.bionMode === "CALIBRATION_ACTIVE"
  );

  const [updateCalibrationReq] = useUpdateCalibrationMutation();

  const [selectedType, setSelectedType] = useState();
  const processNameRef = useRef();
  const descriptionRef = useRef();

  const [createProcessReq, { isLoading: createProcessReqIsLoading }] =
    useCreateProcessMutation();

  const toggleCreation = () => {
    setCreationIsOpen((prevState) => !prevState);
  };

  const closeCalibration = (deactivateCalibration = true) => {
    setCalibrationIsOpen(false);

    if (!deactivateCalibration) return;

    updateCalibrationReq({
      bionId: bion.bionId,
      calibrationActive: false,
      sensorType: "PH",
    });
  };

  const openCalibration = (event) => {
    event.preventDefault();
    setCalibrationIsOpen(true);
  };

  const createProcessHandler = () => {
    // setCalibrationIsOpen(true);
    createProcessReq({
      bionId: bion.bionId,
      processName: processNameRef.current.value,
      description: descriptionRef.current.value,
      processType: selectedType,
      endDate: endDate ? endDate.$d.toISOString() : null,
    });
  };

  // const processNameChangeHandler = (event) => {
  //   setProcessName(event.target.value);
  // };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    createProcessHandler();
  };

  const isDateMoreThan30DaysAgo = (date) => {
    if (!date) return true;

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(date) < thirtyDaysAgo;
  };
  console.log("hiii", calibrationIsOpen);

  return (
    <div className={styles["page"]}>
      <>
        <AnimatePresence>
          {calibrationIsOpen && (
            <CalibrateModal
              onClose={closeCalibration}
              bion={bion}
              createProcess={createProcessHandler}
            />
          )}
        </AnimatePresence>
        {creationIsOpen ? (
          <>
            <header>
              <h1 className={styles["title"]}>
                <img src={BionImg} alt="" />
                Create process for {bion.bionName}
              </h1>
              <p className={styles["subtitle"]}>
                Welcome to the Custom Process Creation Wizard! Here, you have
                the canvas to bring your vision to life. Fill in the details
                with precision as you define the parameters, set conditions, and
                specify each step of your unique bioprocessing journey. This
                intuitive interface guides you through the customization
                process, empowering you to tailor the operation of your BION
                with unparalleled precision. Your input is the brushstroke on
                the canvas of innovation, shaping a process that perfectly
                aligns with your needs and objectives. Let's embark on this
                journey of customization together!
              </p>
            </header>

            <form
              onSubmit={formSubmitHandler}
              className={`${!bion.isLidSet ? styles["hide"] : ""}`}
            >
              <div>
                <label htmlFor="processName">Process Name:</label>
                <input
                  ref={processNameRef}
                  name="processName"
                  id="processName"
                  type="text"
                />
              </div>
              <div>
                <label>Process type:</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  name="color"
                  onChange={(selectedOption) =>
                    setSelectedType(selectedOption.value)
                  }
                  classNames={{
                    control: (state) => styles["control"],
                    menu: () => styles["menu"],
                    input: () => styles["input"],
                  }}
                  options={[
                    // { value: "AE", label: "Aerobic" },
                    // { value: "AN", label: "Anaerobic" },
                    { value: "CG", label: "Cell growth" },
                    { value: "PE", label: "Precision fermentation" },
                  ]}
                  getOptionLabel={(option) => {
                    return <p style={{ color: "white" }}>{option.label}</p>;
                  }}
                />
              </div>
              <div>
                <label htmlFor="processDescription">Description</label>
                <textarea
                  ref={descriptionRef}
                  name="processDescription"
                  id="processDescription"
                ></textarea>
              </div>

              <div className={styles["end-date_input"]}>
                <label>End Date: (Optional)</label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DateTimePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    className={styles["date-time-picker"]}
                    disablePast
                  />
                </LocalizationProvider>
              </div>

              <button type="submit" disabled={createProcessReqIsLoading}>
                {createProcessReqIsLoading ? <LoadingText /> : "Create Process"}
              </button>
            </form>
          </>
        ) : (
          <>
            <header>
              <div>
                <div className={styles["header_row"]}>
                  <h1 className={styles["title"]}>
                    <img src={BionImg} alt="" />
                    {bion.bionName}
                  </h1>
                  <button
                    onClick={openCalibration}
                    className={
                      isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                        ? styles["need-calibration"]
                        : styles["calibrated"]
                    }
                  >
                    <div>
                      <span className={styles["button_title"]}>
                        Sensor Calibration
                      </span>
                      <span className={styles["date"]}>
                        Last time:{" "}
                        {bion?.lastPhCalibrationDate
                          ? new Date(bion.lastPhCalibrationDate).toDateString()
                          : "Never"}
                      </span>
                      {/* <span className={styles["date"]}>
                        {isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                          ? "Calibrate Now"
                          : ""}
                      </span> */}
                    </div>

                    <CalibrationIcon />
                  </button>
                </div>
              </div>
              <p className={styles["subtitle"]}>
                Welcome to the BION Process Creation page, where you have the
                power to design and tailor the operational processes for your
                specific bioreactor. Choose from three distinct pathways: craft
                a custom process to meet your unique requirements, replicate an
                existing one for efficiency, or dive into the innovative BIONIC
                mode for cutting-edge configurations. This intuitive interface
                empowers you to define the parameters that drive your
                bioreactor's performance. Whether you're a precision enthusiast
                or prefer the convenience of proven methods, this page puts the
                control of bioprocessing in your hands, ensuring that your BION
                operates in harmony with your vision.
              </p>
            </header>

            <div>
              <ul className={`${!bion.isLidSet ? styles["hide"] : ""}`}>
                <li>
                  <button
                    onClick={toggleCreation}
                    disabled={isDateMoreThan30DaysAgo(
                      bion.lastPhCalibrationDate
                    )}
                  >
                    <span>
                      <CreationIcon />
                    </span>
                    <span>
                      Unleash your creativity! Design a bespoke process tailored
                      to your specific needs. Define parameters, set conditions,
                      and sculpt a unique path for your BION.
                    </span>
                    <span>Creation</span>
                  </button>
                </li>
                <li>
                  <Link
                    to={
                      isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                        ? "#"
                        : `/files?bionId=${bion.bionId}`
                    }
                  >
                    <span>
                      <ReplicationIcon />
                    </span>
                    <span>
                      Efficiency at your fingertips! Replicate a proven process
                      with a click. Clone and adapt successful configurations
                      for a seamless and reliable operation.
                    </span>
                    <span>Replication</span>
                  </Link>
                </li>
                <li>
                  <button>
                    <span>
                      <BionicIcon />
                    </span>
                    <span>
                      Experience innovation! Step into BIONIC mode to explore
                      cutting-edge configurations. Embrace automated
                      intelligence and let your BION adapt dynamically to
                      optimize performance.
                    </span>
                    <span>Bionic</span>
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default CreateProcess;
