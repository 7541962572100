import { useState } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideNavigation.module.css";
import aiImg from "../../../assets/images/ai.png";
import { RiEditBoxLine as EditIcon } from "react-icons/ri";
import TrainModal from "../TrainModal/TrainModal";
import { useDispatch } from "react-redux";
import { logOut } from "../../../features/auth/authSlice";

import { useGetUserDataQuery } from "../../../features/user/userApiSlice";

import { FcFeedback as FeedbackIcon } from "react-icons/fc";
import { IoAppsSharp as HomeIcon } from "react-icons/io5";
import { BiChevronDown as DownArrowIcon } from "react-icons/bi";

const SideNavigation = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);

  const { data } = useGetUserDataQuery();

  // Assume historyItems is an array of history items. You can fetch or update this list based on your application's needs.
  const historyItems = ["Session 1", "Session 2", "Session 3"]; // Placeholder items

  const location = useLocation();
  const dispatch = useDispatch();

  const openModalHandler = () => {
    setModalIsOpen(true);
  };

  const closeModalHandler = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className={styles.sideNavigation}>
        <button className={styles.editBtn} onClick={openModalHandler}>
          <img src={aiImg} alt="" />
          <span>Cultzyme AI</span>
          <span>
            <EditIcon />
          </span>
        </button>

        <h2>History</h2>
        <ul>
          {historyItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        {/* Actions */}
        <ul className={styles["actions"]}>
          {!location.pathname.includes("home") && (
            <li>
              <Link to="/home" className={styles["feedback__link"]}>
                <span>
                  <HomeIcon style={{ color: "white" }} />
                </span>
                Back to Home
              </Link>
            </li>
          )}
          <li>
            <a
              href="https://us21.list-manage.com/survey?u=3c9a75d368a60cd350eab1916&id=5b8e6529b8&attribution=false"
              target="_blank"
              rel="noreferrer"
              className={styles["feedback__link"]}
            >
              <span>
                <FeedbackIcon />
              </span>
              Thoughts? Share 'em!
            </a>
          </li>
          <li>
            <div className={styles["profile__container"]}>
              <button
                onClick={() => {
                  setProfileMenuIsOpen((prevValue) => !prevValue);
                }}
              >
                <span>JC</span>
                <span>{data?.alias}</span>
                <span>
                  <DownArrowIcon />
                </span>
              </button>
              {profileMenuIsOpen && (
                <motion.div
                  className={styles["profile-menu"]}
                  onClick={() => setProfileMenuIsOpen(false)}
                >
                  <Link to="/settings">Settings</Link>
                  <button
                    onClick={() => {
                      dispatch(logOut());
                    }}
                  >
                    Sign out
                  </button>
                </motion.div>
              )}
            </div>
          </li>
        </ul>
      </div>
      {modalIsOpen && <TrainModal onClose={closeModalHandler} />}
    </>
  );
};

export default SideNavigation;
