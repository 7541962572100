import SideNavigation from "../../components/AI/SideNavigation/SideNavigation";
import ChatContainer from "../../components/AI/ChatContainer/ChatContainer";

import styles from "./AI.module.css";

const AI = () => {
  return (
    <div className={styles.appContainer}>
      <SideNavigation />
      <ChatContainer />
    </div>
  );
};

export default AI;
