import { NavLink, Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";

// import { BsLinkedin as LinkedInIcon } from "react-icons/bs";
// import { HiOutlineDesktopComputer as WebsiteIcon } from "react-icons/hi";
import { BiChevronDown as DownArrowIcon } from "react-icons/bi";
import { MdOutlineScience as ProcessIcon } from "react-icons/md";
import { CgStack as BionsIcon } from "react-icons/cg";
import { FcFeedback as FeedbackIcon } from "react-icons/fc";
import { IoMenu as MenuIcon } from "react-icons/io5";
import { IoCloseSharp as CloseIcon } from "react-icons/io5";
import { IoAppsSharp as HomeIcon } from "react-icons/io5";

import { useGetUserDataQuery } from "../../features/user/userApiSlice";

import styles from "./Navigation.module.css";

import logoImg from "../../assets/images/logo.svg";
import { useEffect, useState } from "react";

const Navigation = () => {
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  const location = useLocation();

  const { data } = useGetUserDataQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    setMobileNavIsOpen(false);
  }, [location]);

  return (
    <>
      <div className={styles["top-mobile-navigation"]}>
        <img src={logoImg} alt="Cultzyme" />
        <button onClick={setMobileNavIsOpen.bind(null, true)}>
          <MenuIcon />
        </button>
      </div>
      <nav
        className={`${styles["navigation"]} ${
          mobileNavIsOpen ? styles["active"] : ""
        }`}
      >
        <div className={styles["mobile-nav-row"]}>
          {/* Logo */}
          <img src={logoImg} alt="Cultzyme" className={styles["logo-img"]} />
          <button onClick={setMobileNavIsOpen.bind(null, false)}>
            <CloseIcon />
          </button>
        </div>

        {/* Pages Navigation */}
        {!location.pathname.includes("home") && (
          <ul className={styles["pages"]}>
            <li>
              <NavLink
                to="/bions"
                className={({ isActive }) =>
                  `${styles["link"]} ${isActive ? styles["active-link"] : ""}`
                }
              >
                <span>
                  <BionsIcon />
                </span>
                Bions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/files"
                className={({ isActive }) =>
                  `${styles["link"]} ${isActive ? styles["active-link"] : ""}`
                }
              >
                <span>
                  <ProcessIcon />
                </span>
                Processes
              </NavLink>
            </li>
          </ul>
        )}

        {/* Actions */}
        <ul className={styles["actions"]}>
          {!location.pathname.includes("home") && (
            <li>
              <Link to="/home" className={styles["feedback__link"]}>
                <span>
                  <HomeIcon style={{ color: "white" }} />
                </span>
                Back to Home
              </Link>
            </li>
          )}
          <li>
            <a
              href="https://us21.list-manage.com/survey?u=3c9a75d368a60cd350eab1916&id=5b8e6529b8&attribution=false"
              target="_blank"
              rel="noreferrer"
              className={styles["feedback__link"]}
            >
              <span>
                <FeedbackIcon />
              </span>
              Thoughts? Share 'em!
            </a>
          </li>
          <li>
            <div className={styles["profile__container"]}>
              <button
                onClick={() => {
                  setProfileMenuIsOpen((prevValue) => !prevValue);
                }}
              >
                <span>JC</span>
                <span>{data?.alias}</span>
                <span>
                  <DownArrowIcon />
                </span>
              </button>
              {profileMenuIsOpen && (
                <motion.div
                  className={styles["profile-menu"]}
                  onClick={() => setProfileMenuIsOpen(false)}
                >
                  <Link to="/settings">Settings</Link>
                  <button
                    onClick={() => {
                      dispatch(logOut());
                    }}
                  >
                    Sign out
                  </button>
                </motion.div>
              )}
            </div>
          </li>
        </ul>

        {/* Footer */}
        <footer>
          {/* <ul className={styles["social"]}>
          <li>
            <a href="https://cultzyme.com/" target="_blank">
              <WebsiteIcon />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/cultzyme/"
              target="_blank"
            >
              <LinkedInIcon />
            </a>
          </li>
        </ul> */}

          <p className={styles["copyright"]}>© Cultzyme 2024</p>
        </footer>

        {/* Toggle Navigation Button */}
        <button className={styles["toggle-nav-button"]}>
          <svg
            width="16"
            height="96"
            viewBox="0 0 16 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#141414"
          >
            <path
              d="M2.5 0H3C3 20 15 12 15 32V64C15 84 3 76 3 96H2.5V0Z"
              fill="black"
              fillOpacity="0.12"
              stroke="transparent"
              strokeWidth="0px"
            ></path>
            <path
              d="M0 0H2.5C2.5 20 14.5 12 14.5 32V64C14.5 84 2.5 76 2.5 96H0V0Z"
              fill="#101010"
            ></path>
          </svg>
          <svg
            className={styles["arrow"]}
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ChevronLeftIcon"
          >
            <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
          </svg>
        </button>
      </nav>
    </>
  );
};

export default Navigation;
