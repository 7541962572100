import { useEffect } from "react";

// eslint-disable-next-line
import styles from "./GradientAnimation.module.css";

const GradientAnimation = ({ overlayStyles }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/javascript/gradientAnimation.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script when the component unmounts
    };
  }, []);
  return (
    <div className={styles["gradient__container"]}>
      <canvas id="gradient-canvas"></canvas>
      <div className={styles["overlay"]} style={overlayStyles}></div>
    </div>
  );
};

export default GradientAnimation;
