import { useEffect, useRef, useState } from "react";

import DigitInput from "../DigitInput/DigitInput";

import { useLazyRequestVerificationCodeQuery } from "../../../features/auth/authApiSlice";
import useTimer from "../../../hooks/use-timer";

import styles from "./Step3.module.css";

// Constants
const digitInputsNumber = 6;
const resendTimerDuration = 60; // 60 seconds (1 minute)

const Step3 = ({ userName, message, onSubmit }) => {
  const [allInputsFilled, setAllInputsFilled] = useState(false);

  // Ref to hold references to digit inputs
  const inputRefs = useRef(new Array(digitInputsNumber));

  const { secondsLeft, restartTimer } = useTimer(resendTimerDuration, {
    startAutomatically: true,
    direction: "desc",
  });

  const [requestVerificationCodeReq] = useLazyRequestVerificationCodeQuery();

  // Effect to handle the submission when all inputs are filled
  useEffect(() => {
    if (allInputsFilled) {
      const verificationCode = Array.from(inputRefs.current).reduce(
        (acc, input) => acc + input.value,
        ""
      );
      onSubmit(verificationCode);
    }
  }, [allInputsFilled, onSubmit]);

  // Event handler for input change
  const inputChangeHandler = (index, event) => {
    const { value } = event.target;

    // Keep only the last digit if the input already has content
    if (value.length > 1) {
      event.target.value = value.charAt(value.length - 1);
    }

    // Move focus to next or previous input based on conditions
    if (value.length >= 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    // Check if all inputs are filled and update state accordingly
    const allFilled = inputRefs.current.every(
      (inputEl) => inputEl.value !== ""
    );
    setAllInputsFilled(allFilled);
  };

  // Event handler for key down to allow only numeric keys and backspace
  const onKeyDownHandler = (index, event) => {
    // Allow only numeric keys and backspace
    const allowedKeys = ["Backspace"];
    if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  // Event handler for key up to move focus back if "Backspace" is clicked and input is empty
  const onKeyUpHandler = (index, event) => {
    if (
      event.key === "Backspace" &&
      index > 0 &&
      event.target.value.length === 0
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const resendBtnClickHandler = () => {
    if (secondsLeft > 0) return;

    requestVerificationCodeReq(userName);
    restartTimer();
  };

  const addInputRef = (index, element) => {
    inputRefs.current[index] = element;
  };

  return (
    <div className={styles["step"]}>
      <h3 className={styles["step-info"]}>
        The final step! We've sent a verification code to your email address.
        Enter it below to complete your account setup.
      </h3>

      <div className={styles["flex"]}>
        <p>Enter code</p>
        <button disabled={secondsLeft} onClick={resendBtnClickHandler}>
          {secondsLeft ? `Resend in ${secondsLeft} seconds` : "Resend code"}
        </button>
      </div>

      <div className={styles["inputs__container"]}>
        {Array.from({ length: digitInputsNumber }).map((_, index) => (
          <DigitInput
            key={index}
            ref={addInputRef.bind(null, index)}
            onChange={inputChangeHandler.bind(null, index)}
            onKeyDown={onKeyDownHandler.bind(null, index)}
            onKeyUp={onKeyUpHandler.bind(null, index)}
            min="0"
            max="9"
            maxLength="1"
          />
        ))}
      </div>

      <p className={styles["step-additional-info"]}>{message}</p>
    </div>
  );
};

export default Step3;
