import { GiCheckMark as ValidIcon } from "react-icons/gi";
import { CircleLoader } from "react-spinners";

import styles from "./CustomInput.module.css";

const CustomInput = ({
  label,
  id,
  register,
  errors,
  watch,
  isLoading = false,
  ...attrs
}) => {
  const errorMsg = errors?.[id]?.message;
  const inputIsNotEmpty = watch && !!watch(id)?.length;

  return (
    <div className={styles["input-control"]}>
      <label htmlFor={id}>{label}</label>
      <div
        className={`${styles["input__container"]} ${
          errorMsg ? styles["invalid"] : ""
        }`}
      >
        <input id={id} type="text" {...register(id)} {...attrs} />
        {!errorMsg && inputIsNotEmpty && (
          <p className={styles["valid-icon"]}>
            <ValidIcon />
          </p>
        )}
        {isLoading && (
          <p className={styles["loading-icon"]}>
            {/* <PulseLoader size={10} color="#ffffff" /> */}
            <CircleLoader size={16} color="#ffffff" />
          </p>
        )}
      </div>
      {errorMsg && <p className={styles["error-msg"]}>{errorMsg}</p>}
    </div>
  );
};

export default CustomInput;
