import React from "react";

import { FaWpforms as FormIcon } from "react-icons/fa";
import { IoLocationOutline as LocationIcon } from "react-icons/io5";
import { MdOutlineVerifiedUser as VerificationIcon } from "react-icons/md";

import styles from "./ProgressBar.module.css";

const ProgressBar = ({ bionId, currentStep }) => {
  let progressBarWidth = "0%";
  if (currentStep === 2 && bionId) {
    progressBarWidth = "50%";
  } else if (currentStep === 3 && bionId) {
    progressBarWidth = "100%";
  } else if (currentStep === 2 && !bionId) {
    progressBarWidth = "100%";
  }

  return (
    <div className={styles["progress__container"]}>
      <div className={styles["progress-bar"]}>
        <div
          className={styles["progress-bar--inner"]}
          style={{ width: progressBarWidth }}
        ></div>
      </div>
      <div
        className={`${styles["progress-step"]} ${
          currentStep >= 1 ? styles["active"] : ""
        }`}
      >
        <FormIcon />
      </div>
      {bionId && (
        <div
          className={`${styles["progress-step"]} ${
            currentStep >= 2 ? styles["active"] : ""
          }`}
        >
          <LocationIcon />
        </div>
      )}
      <div
        className={`${styles["progress-step"]} ${
          currentStep >= 3 ? styles["active"] : ""
        }`}
      >
        <VerificationIcon />
      </div>
    </div>
  );
};

export default ProgressBar;
