import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./TrainModal.module.css";
import ModalWrapper from "../../Wrappers/ModalWrapper/ModalWrapper";
import {
  useGetUserDocumentsQuery,
  useUploadDocumentsMutation,
  useDeleteDocumentsMutation,
} from "../../../features/document/documentApiSlice";

const TrainModal = ({ onClose }) => {
  const { data: documents, isLoading, isSuccess } = useGetUserDocumentsQuery();

  const [uploadDocumentsReq, { isLoading: uploadDocumentsReqIsLoading }] =
    useUploadDocumentsMutation();

  const [deleteDocumentReq, { isLaoding: deleteDocumentReqIsLaoding }] =
    useDeleteDocumentsMutation();

  const [files, setFiles] = useState(documents || []);

  const hasFiles = files.some((file) => file instanceof File);

  useEffect(() => {
    if (isSuccess && documents) setFiles(documents);
  }, [isSuccess, documents]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Calculate total size of files
      const totalSize =
        acceptedFiles.reduce((acc, file) => acc + file.size, 0) +
        files.reduce((acc, file) => acc + file.size, 0);

      // Check if total size exceeds 512 MB (512 * 1024 * 1024 bytes)
      if (totalSize > 512 * 1024 * 1024) {
        alert(
          "The total size of the files exceeds 512 MB. Please select fewer files or smaller files."
        );
        return; // Prevent further processing
      }

      // If size is within limit, proceed with file processing

      setFiles((currentFiles) => [...currentFiles, ...acceptedFiles]);
    },
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const onTrain = () => {
    console.log("Training with files: ", files);
    uploadDocumentsReq(files);
    // Placeholder for training functionality
  };

  const onRemoveFile = (fileName) => {
    deleteDocumentReq([fileName]);
    setFiles((currentFiles) =>
      currentFiles.filter((file) => file.name !== fileName)
    );
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2>Train AI</h2>
          </div>
          <div {...getRootProps()} className={styles.fileDropArea}>
            <input {...getInputProps()} />
            Drop files here or click to upload
          </div>
          <button
            className={styles.trainButton}
            onClick={onTrain}
            disabled={uploadDocumentsReqIsLoading || !hasFiles}
          >
            {uploadDocumentsReqIsLoading ? "Training AI..." : "Train"}
          </button>
          <div className={styles.fileList}>
            {files.map((file) => {
              const date = new Date(file.dataIngestedDate);
              return (
                <div key={file.name} className={styles.fileItem}>
                  <span>
                    {file.name} - {date.toLocaleString()}
                  </span>

                  {file.type && (
                    <span className={styles["info"]}>Needs trainning</span>
                  )}
                  <button onClick={() => onRemoveFile(file.name)}>
                    Remove
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TrainModal;
