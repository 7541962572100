import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/Signup/Input/CustomInput";
import useTimer from "../../hooks/use-timer";

import GradientAnimation from "../../components/UI/GradientAnimation/GradientAnimation";
import logoImg from "../../assets/images/logo.png";
import { AiOutlineLeft as LeftArrowIcon } from "react-icons/ai";

import styles from "./ForgetPassword.module.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ForgetPasswordSchema from "../../validations/forgetPassword";

import { useLazyForgetPasswordQuery } from "../../features/auth/authApiSlice";
import { useConfirmPasswordRetrievalMutation } from "../../features/auth/authApiSlice";

const ForgetPassword = () => {
  const { secondsLeft, restartTimer } = useTimer(60, {
    startAutomatically: true,
    direction: "desc",
  });

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    // formState: { isValid: isValid1 },
    reset,
  } = useForm({
    resolver: yupResolver(ForgetPasswordSchema.ForgetPasswordSchema1),
  });

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    // formState: { isValid: isValid2 },
  } = useForm({
    resolver: yupResolver(ForgetPasswordSchema.ForgetPasswordSchema2),
  });

  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [userName, setUsername] = useState("");

  const [forgetPasswordReq, { data, isLoading: forgetPasswordReqIsLoading }] =
    useLazyForgetPasswordQuery();

  const [
    confirmPasswordRetrievalReq,
    { isLoading: confirmPasswordRetrievalReqIsLoading },
  ] = useConfirmPasswordRetrievalMutation();

  const forgetPasswordSubmitHandler = async (data) => {
    try {
      const responseData = await forgetPasswordReq(
        data.usernameOrEmail
      ).unwrap();
      if (responseData.status >= 400 && responseData.status <= 599) return;
      setUsername(data.usernameOrEmail);
      setStep(2);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPasswordRetrievalHandler = async (data) => {
    console.log(data, "hi");
    try {
      await confirmPasswordRetrievalReq({ ...data, usernameOrEmail: userName });
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const resendBtnClickHandler = () => {
    forgetPasswordReq(userName);
    restartTimer();
  };

  return (
    <div className={styles["page"]}>
      <div className={styles["right-side__container"]}>
        <div className={styles["gradient-animation__container"]}>
          <GradientAnimation
          // overlayStyles={{
          // background: "rgba(16, 16, 16, 0.5)",
          // backdropFilter: " blur(7px)",
          // }}
          />
        </div>
        <img className={styles["logo"]} src={logoImg} alt="Cultzyme" />
      </div>
      <div className={styles["container"]}>
        {/* Main Navigation */}
        <nav className={styles["main-navigation"]}>
          <button>
            <LeftArrowIcon />
          </button>
          <h1>Password Recovery</h1>
          {/* <h2>Welcome back!</h2> */}
        </nav>
        {/* End of Main Navigation */}

        {/* Login */}
        <div className={styles["step"]}>
          <h3 className={styles["step-info"]}>
            No worries! We've got you covered. Please enter the email address
            associated with your account, and we'll send you a link to reset
            your password. Stay secure!
          </h3>
          {step === 1 ? (
            <form
              id="login-form"
              onSubmit={handleSubmit1(forgetPasswordSubmitHandler)}
              className={styles["login-form"]}
            >
              <CustomInput
                label="Email or Username"
                id="usernameOrEmail"
                register={register1}
                type="text"
                placeholder="Use email or username"
                autoComplete="off"
              />
            </form>
          ) : (
            <form
              id="forget-form"
              onSubmit={handleSubmit2(confirmPasswordRetrievalHandler)}
              className={styles["login-form"]}
            >
              <CustomInput
                label="Verification Code"
                id="confirmationCode"
                register={register2}
                type="text"
                placeholder="Verification Code received"
                autoComplete="off"
              />

              <CustomInput
                label="New Password"
                id="newPassword"
                register={register2}
                type="password"
                placeholder="Enter your new password"
                autoComplete="off"
              />

              <button
                style={{
                  color: "#fff",
                  // marginTop: "4px",
                  textAlign: "center",
                }}
                disabled={secondsLeft}
                onClick={resendBtnClickHandler}
              >
                {secondsLeft
                  ? `Resend in ${secondsLeft} seconds`
                  : "Resend code"}
              </button>
            </form>
          )}
        </div>

        {/* Fixed bottom Section */}
        <div className={styles["bottom-fixed__container"]}>
          <div className={styles["button__container"]}>
            {step === 1 ? (
              <button
                form="login-form"
                // disabled={!isValid1 || forgetPasswordReqIsLoading}
                // className={!isValid ? styles["disabled"] : ""}
              >
                {forgetPasswordReqIsLoading ? "Loading..." : "Reset Password"}
              </button>
            ) : (
              <button
                form="forget-form"
                // disabled={!isValid1 || forgetPasswordReqIsLoading}
                // className={!isValid ? styles["disabled"] : ""}
              >
                {confirmPasswordRetrievalReqIsLoading
                  ? "Loading..."
                  : "Reset Password"}
              </button>
            )}
          </div>
        </div>
        {/* End of Fixed bottom Section */}
      </div>
    </div>
  );
};

export default ForgetPassword;
