import { apiSlice } from "../../app/api/apiSlice";
import pusher from "../../app/pusher";

export const processApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Retrieves data related to the user's running processes
    getUserRunningProcessesData: build.query({
      query: (bionId) => `/processes/users/${bionId}`,
      providesTags: (result, error, bionId) => [
        { type: "Process", id: bionId },
      ],
      async onCacheEntryAdded(
        bionId,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        }
      ) {
        const userId = getState().auth.user.userId;
        const userName = getState().auth.user.userId;

        // Create a Pusher connection when the cache subscription starts
        pusher.initializePusher();

        try {
          // Wait for the initial query to resolve before proceeding
          const {
            data: { processId },
          } = await cacheDataLoaded;

          // Subscribe to Pusher channels and Bind to events
          pusher.subscribeToChannels(
            {
              channelName: `private-machine-status-channel-${bionId}`,
              eventName: "machine-status-changed",
            },
            {
              channelName: `private-gas-channel-${bionId}`,
              eventName: "gas-status-changed",
            },
            {
              channelName: `private-light-channel-${bionId}`,
              eventName: "light-status-changed",
            },
            {
              channelName: `private-stir-channel-${bionId}`,
              eventName: "stir-status-changed",
            },
            {
              channelName: `private-temperature-channel-${bionId}`,
              eventName: "temperature-status-changed",
            },
            {
              channelName: `private-ultra-sounds-channel-${bionId}`,
              eventName: "ultra-sounds-status-changed",
            },
            {
              channelName: `private-ultra-violet-channel-${bionId}`,
              eventName: "ultra-violet-status-changed",
            },
            {
              channelName: `private-vacuum-channel-${bionId}`,
              eventName: "vacuum-status-changed",
            },
            (receivedData) => {
              updateCachedData((process) => {
                for (const key in receivedData) {
                  process[key] = receivedData[key];
                  process.isPure = false;
                }
              });
            }
          );

          pusher.subscribeToChannels(
            {
              channelName: `private-sensors-channel-${bionId}`,
              eventName: "sensors-status-changed",
            },
            (receivedData) => {
              updateCachedData((process) => {
                for (const key in receivedData) {
                  process[key] = receivedData[key];
                  process.isPure = false;
                }
              });

              dispatch(
                apiSlice.util.updateQueryData(
                  "getSensorDataForProcess",
                  processId,
                  (draftSensors) => {
                    console.log("enteringgg");
                    draftSensors.forEach((draftSensor) => {
                      const draftSensorName =
                        draftSensor.name.charAt(0).toLowerCase() +
                        draftSensor.name.slice(1);

                      if (draftSensorName in receivedData) {
                        draftSensor.data.push([
                          receivedData.createdDate,
                          receivedData[draftSensorName],
                        ]);
                      }
                    });
                  }
                )
              );
            }
          );

          // Subscribe to Pusher channels and Bind to events
          pusher.subscribeToChannels(
            {
              channelName: `private-ultra-violet-channel-${bionId}`,
              eventName: "ultra-violet-status-changed",
            },

            (receivedData) => {
              updateCachedData((process) => {
                // Convert receivedData.ultraVioletState to a boolean
                const receivedState = receivedData.ultraVioletState;

                // Find the index of the element with the same ultraVioletMode as receivedData
                const index = process.ultraVioletModes.findIndex(
                  (mode) =>
                    mode.ultraVioletMode === receivedData.ultraVioletMode
                );

                // If the mode is found, update its ultraVioletState
                if (index !== -1) {
                  process.ultraVioletModes[index].ultraVioletState =
                    receivedState;
                } else {
                  // If the mode is not found and there are less than 2 modes, add the new mode-state object
                  if (process.ultraVioletModes.length < 2) {
                    process.ultraVioletModes.push({
                      ultraVioletMode: receivedData.ultraVioletMode,
                      ultraVioletState: receivedState,
                    });
                  }
                }

                // Ensure the isPure flag is correctly updated
                process.isPure = false;
              });
            }
          );

          pusher.subscribeToChannels(
            {
              channelName: `private-process-changed-channel-${userId}`,
              eventName: "process-changed",
            },
            ({ processMode, endDate }) => {
              console.log(endDate);
              updateCachedData((process) => {
                console.log("entered");
                if (processMode === "RE") {
                  process.endDate = endDate;
                }
              });
            }
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        pusher.unsubscribeFromChannels(
          `private-machine-status-channel-${bionId}`,
          `private-gas-channel-${bionId}`,
          `private-light-channel-${bionId}`,
          `private-stir-channel-${bionId}`,
          `private-temperature-channel-${bionId}`,
          `private-ultra-sounds-channel-${bionId}`,
          `private-ultra-violet-channel-${bionId}`,
          `private-vacuum-channel-${bionId}`,
          `private-sensors-channel-${bionId}`
          // `private-process-changed-channel-${userId}`
        );
      },
    }),

    // Retrieves all finished processes of a user
    getFinishedUserProcesses: build.query({
      query: () => "/processes/finished",
    }),

    // Creates a new process
    createProcess: build.mutation({
      query: ({ bionId, processName, description, processType, endDate }) => ({
        url: "/processes",
        method: "POST",
        body: { bionId, processName, description, processType, endDate },
      }),
    }),

    // Initiates the replication of a process for a specified Bion
    startProcessReplication: build.mutation({
      query: ({ processId, bionId }) => ({
        url: `/processes/${processId}/replicate-start/${bionId}`,
        method: "POST",
        body: { processId, bionId },
      }),
    }),

    sendActuatorData: build.mutation({
      query: ({ actuatorName, data }) => ({
        url: `/actuators/${actuatorName}`,
        method: "POST",
        body: data,
      }),
      async onQueryStarted(
        { actuatorName, data },
        { dispatch, queryFulfilled }
      ) {
        const postResult = dispatch(
          apiSlice.util.updateQueryData(
            "getUserRunningProcessesData",
            data.bionId,
            (process) => {
              process.isPure = false;
              if (actuatorName === "ultra-violet") {
                // Assume data contains ultraVioletModes changes
                const { ultraVioletModes } = data;
                if (!process.ultraVioletModes) {
                  process.ultraVioletModes = [];
                }
                ultraVioletModes.forEach((update) => {
                  const index = process.ultraVioletModes.findIndex(
                    (mode) => mode.ultraVioletMode === update.ultraVioletMode
                  );
                  if (index > -1) {
                    // Update existing mode state
                    process.ultraVioletModes[index] = update;
                  } else {
                    // Add new mode if it doesn't exist
                    process.ultraVioletModes.push(update);
                  }
                });
              } else {
                // Handle other actuators' updates
                for (const key in data) {
                  if (data.hasOwnProperty(key)) {
                    process[key] = data[key];
                  }
                }
              }
            }
          )
        );

        queryFulfilled.catch(postResult.undo);
      },
    }),

    // getActuatorData: build.query({
    //   query: (processId) => `/actuators/${processId}`,
    // }),

    // Modifies data of a running process
    modifyProcessData: build.mutation({
      query: ({ processId, processName, description }) => ({
        url: "/processes",
        method: "PUT",
        body: { processId, processName, description },
      }),
      async onQueryStarted(
        { bionId, processId, processName, description },
        { dispatch, queryFulfilled }
      ) {
        const postResult = dispatch(
          apiSlice.util.updateQueryData(
            "getUserRunningProcessesData",
            bionId,
            (process) => {
              process.processName = processName;
              process.description = description;
            }
          )
        );

        queryFulfilled.catch(postResult.undo);
      },
    }),

    // Manually finishes an ongoing replicating process
    finishReplicatingProcess: build.mutation({
      query: ({ processId }) => ({
        url: `/processes/${processId}/replicate-finish`,
        method: "PUT",
      }),
    }),

    // Completes a process and saves its data
    finishAndSaveProcess: build.mutation({
      query: ({ processId, bionId }) => ({
        url: "/processes/finish",
        method: "PUT",
        body: { processId },
      }),
      async onQueryStarted({ bionId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const postResult = dispatch(
            apiSlice.util.updateQueryData(
              "getReadyBions",
              undefined,
              (bions) => {
                const bionToUpdate = bions.find(
                  (bion) => bion.bionId === bionId
                );
                bionToUpdate.bionMode = null;
              }
            )
          );
        } catch {}

        // queryFulfilled.catch(postResult.undo);
      },
      // invalidatesTags: (result, error, { bionId }) => [
      //   { type: "Process", id: bionId },
      // ],
    }),

    // Deletes all data associated with a specific process when the user finishes the given process
    finishAndDeleteProcess: build.mutation({
      query: ({ processId, bionId }) => ({
        url: `/processes/${processId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ bionId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const postResult = dispatch(
            apiSlice.util.updateQueryData(
              "getReadyBions",
              undefined,
              (bions) => {
                const bionToUpdate = bions.find(
                  (bion) => bion.bionId === bionId
                );
                bionToUpdate.bionMode = null;
              }
            )
          );
        } catch {}

        // queryFulfilled.catch(postResult.undo);
      },
      // invalidatesTags: (result, error, { bionId }) => [
      //   { type: "Process", id: bionId },
      // ],
    }),

    // Deletes the list of processes associated with the user
    deleteUserProcesses: build.mutation({
      query: (processIds) => ({
        url: "/processes",
        method: "DELETE",
        body: processIds,
      }),
      async onQueryStarted({ processIds }, { dispatch, queryFulfilled }) {
        const postResult = dispatch(
          apiSlice.util.updateQueryData(
            "getFinishedUserProcesses",
            undefined,
            (processes) => {
              return processes.filter(
                (process) => !processIds.includes(process.processId)
              );
            }
          )
        );

        queryFulfilled.catch(postResult.undo);
      },
    }),
  }),
});

export const {
  useGetUserRunningProcessesDataQuery,
  useGetFinishedUserProcessesQuery,
  useCreateProcessMutation,
  useSendActuatorDataMutation,
  useStartProcessReplicationMutation,
  useFinishReplicatingProcessMutation,
  useFinishAndSaveProcessMutation,
  useFinishAndDeleteProcessMutation,
  useDeleteUserProcessesMutation,
  useModifyProcessDataMutation,
} = processApiSlice;
