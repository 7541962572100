import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { AiOutlineEdit as EditIcon } from "react-icons/ai";

import CircularProgressBar from "./CircularProgressBar/CircularProgressBar";
import Ultrasound from "./Actuators/Ultrasound/Ultrasound";
import Vacuum from "./Actuators/Vacuum/Vacuum";
import Ultraviolet from "./Actuators/Ultraviolet/Ultraviolet";
import Light from "./Actuators/Light/Light";
import MagneticStirring from "./Actuators/MagneticStirring/MagneticStirring";
import Gas from "./Actuators/Gas/Gas";
import Temperature from "./Actuators/Temperature/Temperature";
import Ph from "./Actuators/Ph/Ph";
import Chart from "./Chart/Chart";
import FinishProcess from "./FinishProcess/FinishProcess";

import useTimer from "../../../hooks/use-timer";

import {
  useGetUserRunningProcessesDataQuery,
  useSendActuatorDataMutation,
} from "../../../features/process/processApiSlice";

import styles from "./Replication.module.css";
import UpdateProcess from "./UpdateProcess/UpdateProcess";
import ManualData from "./ManualData/ManualData";

const Process = ({ bion }) => {
  const [finishProcess, setFinishProcess] = useState(false);
  const [updateProcess, setUpdateProcess] = useState(false);
  const [manualData, setManualData] = useState(false);

  const { data: process, isLoading: getUserRunningProcessesDataReqIsLoading } =
    useGetUserRunningProcessesDataQuery(bion.bionId);

  const timeLeftBeforeReplicationFinish = Math.floor(
    (new Date(process?.endDate) - new Date()) / 1000
  );

  console.log(process);

  const [sendActuatorDataReq] = useSendActuatorDataMutation();

  const sendActuatorDataHandler = ({ actuatorName, ...data }) => {
    sendActuatorDataReq({
      actuatorName: actuatorName,
      data: { ...data, processId: process.processId, bionId: bion.bionId },
    });
  };

  const openFinishProcess = () => {
    setFinishProcess(true);
  };
  const closeFinishProcess = () => {
    setFinishProcess(false);
  };

  const openUpdateProcess = () => {
    setUpdateProcess(true);
  };
  const closeUpdateProcess = () => {
    setUpdateProcess(false);
  };

  const totalTime =
    (new Date(process?.endDate) - new Date(process?.startDate)) / 1000;

  const { secondsLeft } = useTimer(timeLeftBeforeReplicationFinish, {
    startAutomatically: true,
    direction: "desc",
  });

  if (getUserRunningProcessesDataReqIsLoading) return;

  return (
    <>
      <div className={styles["process-page"]}>
        <motion.div
          variants={{
            hidden: {},
            visible: { transition: { staggerChildren: 0.05 } },
          }}
          initial="hidden"
          animate="visible"
          className={styles["container"]}
        >
          <div className={styles["big-screens_row"]}>
            {/* Info */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              className={`${styles["info"]} ${styles["box"]}`}
            >
              <h1
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  fontWeight: 900,
                  textDecoration: "underline",
                }}
              >
                Replicating Process
              </h1>
              <div
                className={styles["big__row"]}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <div className={styles["edit__container"]}>
                    <h1>
                      <span>{/* <ProcessIcon /> */}</span> Process:{" "}
                      <span>{process.processName}</span>
                    </h1>
                  </div>
                  <div className={styles["edit__container"]}>
                    <h2>
                      Description: <span>{process.description}</span>
                    </h2>
                  </div>
                  <div className={styles["edit__container"]}>
                    <h2>
                      <span>{/* <BionIcon /> */}</span> Bion:{" "}
                      <span>{bion.bionName}</span>
                    </h2>
                  </div>
                  <h2>
                    Type: <span>{process.processTypeDescription}</span>
                  </h2>
                  <h2>
                    <span>{/* <BionIcon /> */}</span> Start Date:{" "}
                    <span>{new Date(process.startDate).toLocaleString()}</span>
                  </h2>
                  {process?.endDate ? (
                    <h2>
                      <span>{/* <BionIcon /> */}</span> End Date:{" "}
                      <span>{new Date(process.endDate).toLocaleString()}</span>
                    </h2>
                  ) : (
                    ""
                  )}
                  {process.isPolluted && (
                    <h2>
                      <span>{/* <BionIcon /> */}</span> Contaminated:{" "}
                      <span>Yes</span>
                    </h2>
                  )}

                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      marginTop: "auto",
                    }}
                  >
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 1 }}
                      onClick={openFinishProcess}
                    >
                      Finish
                    </motion.button>
                  </div>
                </div>
                <div className={styles["progress-bar"]}>
                  <div
                    className={styles["progress-bar__inner"]}
                    style={{
                      transform: `translateX(${
                        100 - (secondsLeft / totalTime) * 100
                      }%)`,
                    }}
                  ></div>
                </div>
              </div>
            </motion.div>

            {/* Status Level */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              className={`${styles["status-level"]} ${styles["box"]}`}
            >
              <h3>SENSORS</h3>
              <div className={styles["progress-bars__container"]}>
                {/* => Temperature <=  */}
                <CircularProgressBar
                  value={process.temperature}
                  colorRanges={[
                    { min: 0, max: 30, color: "#3E98C7" },
                    { min: 30, max: 60, color: "#ff5521" },
                    { min: 60, max: 80, color: "#fc1717" },
                  ]}
                >
                  C
                </CircularProgressBar>

                {/* => Carbon Dioxide <=  */}
                <CircularProgressBar
                  value={process.carbonDioxide}
                  unit="%"
                  colorRanges={[
                    { min: 0, max: 25, color: "#3E98C7" },
                    { min: 25, max: 50, color: "#34ff21" },
                    { min: 50, max: 75, color: "#ff5521" },
                    { min: 75, max: 100, color: "#fc1717" },
                  ]}
                >
                  CO<sub>2</sub>
                </CircularProgressBar>

                {/* => Oxygen <=  */}
                <CircularProgressBar
                  value={process.oxygen}
                  unit="%"
                  colorRanges={[
                    { min: 0, max: 25, color: "#3E98C7" },
                    { min: 25, max: 50, color: "#34ff21" },
                    { min: 50, max: 75, color: "#ff5521" },
                    { min: 75, max: 100, color: "#fc1717" },
                  ]}
                >
                  O<sub>2</sub>
                </CircularProgressBar>

                {/* => Nitrogen <=  */}
                {/* <CircularProgressBar
                  value={process.nitrogen}
                  unit="%"
                  colorRanges={[
                    { min: 0, max: 25, color: "#3E98C7" },
                    { min: 25, max: 50, color: "#34ff21" },
                    { min: 50, max: 75, color: "#ff5521" },
                    { min: 75, max: 100, color: "#fc1717" },
                  ]}
                >
                  NH<sub>4</sub>
                  <sup>+</sup>
                </CircularProgressBar> */}

                {/* Volume */}
                <CircularProgressBar
                  value={process.volume}
                  unit="%"
                  colorRanges={[{ min: 0, max: 100, color: "#3E98C7" }]}
                >
                  Volume
                </CircularProgressBar>

                {/* => Ethanol <=  */}
                <CircularProgressBar
                  value={process.ethanol}
                  unit="%"
                  colorRanges={[
                    { min: 0, max: 25, color: "#3E98C7" },
                    { min: 25, max: 50, color: "#34ff21" },
                    { min: 50, max: 75, color: "#ff5521" },
                    { min: 75, max: 100, color: "#fc1717" },
                  ]}
                >
                  Ethanol
                </CircularProgressBar>

                {/* Acidity */}
                <CircularProgressBar
                  value={process.acidity}
                  colorRanges={[
                    { min: 0, max: 4, color: "#3E98C7" },
                    { min: 4, max: 7, color: "#34ff21" },
                    { min: 7, max: 14, color: "#fbff21" },
                  ]}
                >
                  pH
                </CircularProgressBar>
              </div>
            </motion.div>
          </div>

          {/* Actuators  */}
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -100 },
              visible: { opacity: 1, y: 0 },
            }}
            className={`${styles["actuators"]} ${styles["box"]} `}
          >
            <h3>ACTUATORS</h3>

            <div
              className={`${styles["actuators__container"]} ${
                !bion.isLidSet ? styles["hide"] : ""
              }`}
            >
              {/* => Gas <= */}
              <Gas
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Vacuum <= */}
              <Vacuum
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Ultraviolet <= */}
              <Ultraviolet
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Light <= */}
              <Light
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Ultrasound <= */}
              <Ultrasound
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Magnetic Stirring <= */}
              <MagneticStirring
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => Temperature <= */}
              <Temperature
                process={process}
                sendActuatorData={sendActuatorDataHandler}
              />

              {/* => PH <= */}
              <Ph />
            </div>
          </motion.div>

          {/* Chart  */}
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 100 },
              visible: { opacity: 1, x: 0 },
            }}
            className={`${styles["chart"]} ${styles["box"]}`}
          >
            <h3>SENSOR EVOLUTION CHART</h3>

            <div>
              <Chart bion={bion} processId={process.processId} />
            </div>
          </motion.div>
        </motion.div>
      </div>

      <AnimatePresence>
        {finishProcess && (
          <FinishProcess
            process={process}
            bionId={bion.bionId}
            onClose={closeFinishProcess}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {updateProcess && (
          <UpdateProcess
            process={process}
            bionId={bion.bionId}
            onClose={closeUpdateProcess}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {manualData && (
          <ManualData
            onClose={() => {
              setManualData(false);
            }}
            process={process}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Process;
