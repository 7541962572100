import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "./features/auth/authSlice";
import { apiSlice } from "./app/api/apiSlice";

import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import RequireAuth from "./features/auth/RequireAuth";
import AppWrapper from "./components/Wrappers/AppWrapper/AppWrapper";
import Bions from "./pages/Bions/Bions";
import Bion from "./pages/Bion/Bion";
import Files from "./pages/Files/Files";
import Settings from "./pages/Settings/Settings";
import RegistrationRequest from "./pages/RegistrationRequest/RegistrationRequest";
import Process from "./pages/Process/Process";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Home from "./pages/Home/Home";
import AI from "./pages/AI/AI";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!token);

  useEffect(() => {
    if (!token && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }

    setTokenExisted(!!token);
  }, [token, dispatch, tokenExisted, setTokenExisted]);

  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route element={<RequireAuth />}>
        <Route path="/cultzyme-ai" element={<AI />} />

        <Route element={<AppWrapper />}>
          <Route path="/home" element={<Home />} />
          {/* Home Page */}
          {/* Bions Page  */}
          <Route path="/bions" element={<Bions />} />

          {/* Specific Bion Page */}
          <Route path="/bions/:bionId" element={<Bion />} />

          {/* Files Page */}
          <Route path="/files" element={<Files />} />

          {/* Settings Page */}
          <Route path="/settings" element={<Settings />} />

          {/* Specific Process Page */}
          <Route path="/processes/:processId" element={<Process />} />
          {/* Account Actions: approve or reject */}

          <Route
            path="auth/accounts/:action"
            element={<RegistrationRequest />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default App;
