import { forwardRef } from "react";

import styles from "./DigitInput.module.css";

const DigitInput = forwardRef(({ ...attrs }, ref) => {
  return (
    <div className={styles["input__container"]}>
      <input ref={ref} type="number" {...attrs} />
    </div>
  );
});

export default DigitInput;
