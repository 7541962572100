import { useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";

import Process from "../../components/Bion/Process/Process";
import Replication from "../../components/Bion/Replication/Replication";
import CreateProcess from "../../components/Bion/CreateProcess/CreateProcess";

import { useGetReadyBionsQuery } from "../../features/bion/bionApiSlice";

const Bion = () => {
  const { bionId } = useParams();

  const selectBionById = useMemo(() => {
    const emptyObject = {};

    return createSelector(
      (result) => result.data,
      (bions, bionId) => bionId,
      (bions, bionId) =>
        bions?.find((bion) => bion.bionId === bionId) ?? emptyObject
    );
  }, []);

  const { bion } = useGetReadyBionsQuery(undefined, {
    selectFromResult: (result) => ({
      // ...result,
      bion: selectBionById(result, bionId),
    }),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!bion.isOn) {
      navigate("/bions");
    }
  }, [bion.isOn, navigate]);

  // Determine which page component to render based on 'bion' properties
  const PageToRender =
    bion?.bionMode === "PROCESS_ACTIVE" ? (
      // Render the 'Process' component if 'processActive' is true
      <Process bion={bion} />
    ) : bion?.bionMode === "REPLICATE_ACTIVE" ? (
      // Render the 'Replication' component if 'replicateActive' is true
      <Replication bion={bion} />
    ) : (
      // Otherwise, render the 'CreateProcess' component
      <CreateProcess bion={bion} />
    );

  // Return the selected page component to be rendered
  return PageToRender;
};

export default Bion;
