import { useEffect, useRef } from "react";
import styles from "./MessageList.module.css";

import { useGetUserDataQuery } from "../../../features/user/userApiSlice";

const MessageList = ({ messages }) => {
  const { data } = useGetUserDataQuery();
  // useRef to keep a reference to the end of the chat
  const endOfMessagesRef = useRef(null);

  // useEffect to scroll into view every time messages update
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <div className={styles.messageList}>
      <div className={styles.container}>
        {messages.map((message, index) => (
          <div key={index} className={styles["flex"]}>
            {message.sender === "user" ? (
              <div className={styles["left-side"]}>
                {data?.alias[0].toUpperCase()}
              </div>
            ) : (
              <div className={`${styles["left-side"]} ${styles["assistant"]}`}>
                AI
              </div>
            )}
            <div
              className={`${styles.message} ${
                message.sender === "user"
                  ? styles.userMessage
                  : styles.aiMessage
              }`}
            >
              <p className={styles["sender"]}>
                <strong>
                  {message.sender === "user" ? "You" : "Cultzyme AI"}
                </strong>
              </p>
              {message.text}
            </div>
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
    </div>
  );
};

export default MessageList;
