import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AiOutlineLeft as LeftArrowIcon } from "react-icons/ai";

import Step1 from "../../components/Signup/Step1/Step1";
import Step2 from "../../components/Signup/Step2/Step2";
import Step3 from "../../components/Signup/Step3/Step3";
import Step4 from "../../components/Signup/Step4/Step4";
import ProgressBar from "../../components/Signup/ProgressBar/ProgressBar";
import GradientAnimation from "../../components/UI/GradientAnimation/GradientAnimation";

import logoImg from "../../assets/images/logo.png";

import {
  useSignupUserMutation,
  useSignupUserWithoutBionMutation,
  useConfirmUserAccountMutation,
} from "../../features/auth/authApiSlice";

import styles from "./Signup.module.css";

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [step1, setStep1] = useState({ data: {}, isValid: false });

  const location = useLocation();
  const bionId = new URLSearchParams(location.search).get("destination");

  const navigate = useNavigate();

  const [
    signupUserReq,
    {
      data: newUser,
      isLoading: signupUserReqIsLoading,
      isSuccess: signupUserReqIsSuccess,
    },
  ] = useSignupUserMutation();

  const [
    signupUserWithoutBionReq,
    {
      data: newUserWithoutBion,
      isLoading: signupUserWithoutBionIsLoading,
      isSuccess: signupUserWithoutBionIsSuccess,
    },
  ] = useSignupUserWithoutBionMutation();

  // Effect to handle the successful signup request
  useEffect(() => {
    if (signupUserReqIsSuccess) {
      setCurrentStep(newUser?.isAdmin ? 3 : 4);
    }

    if (signupUserWithoutBionIsSuccess) {
      setCurrentStep(3);
    }
  }, [signupUserReqIsSuccess, signupUserWithoutBionIsSuccess, newUser]);

  const [
    confirmUserAccountReq,
    {
      isLoading: confirmUserAccountReqIsLoading,
      isSuccess: confirmUserAccountReqIsSuccess,
    },
  ] = useConfirmUserAccountMutation();

  // Effect to handle the successful confirm user request
  useEffect(() => {
    if (confirmUserAccountReqIsSuccess) {
      navigate("/login", { replace: true });
    }
  }, [confirmUserAccountReqIsSuccess, navigate]);

  // Handler for submitting Step1
  const step1SubmitHandler = (step1Data) => {
    console.log("hii", step1Data);
    setStep1((step1) => ({ ...step1, data: step1Data }));
    if (!bionId) {
      signupUserWithoutBionReq({
        email: step1Data.email,
        userName: step1Data.userName,
        password: step1Data.password,
        alias: step1Data.alias,
        phoneNumber: step1Data.countryCode + step1Data.phone,
      });
    } else {
      setCurrentStep(2);
    }
  };

  // Handler for changes in Step 1 validity
  const step1ValidityChangeHandler = useCallback((isValid) => {
    setStep1((step1) => ({ ...step1, isValid }));
  }, []);

  // Handler for submitting Step2
  const step2SubmitHandler = useCallback(
    (step2Data) => {
      signupUserReq({
        destination: bionId,
        email: step1.data.email,
        userName: step1.data.userName,
        password: step1.data.password,
        bionName: step1.data.bionName,
        alias: step1.data.alias,
        phoneNumber: step1.data.countryCode + step1.data.phone,
        latitude: step2Data.latitude,
        longitude: step2Data.longitude,
      });
    },
    [signupUserReq, step1, bionId]
  );

  // Handler for submitting Step3
  const step3SubmitHandler = useCallback(
    (verificationCode) => {
      confirmUserAccountReq({
        confirmationCode: verificationCode,
        userName: newUser?.userName || newUserWithoutBion?.userName,
        destination: bionId,
      });
    },
    [
      confirmUserAccountReq,
      newUser?.userName,
      newUserWithoutBion?.userName,
      bionId,
    ]
  );

  // Define the appropriate content to render based on the current step
  let StepToRender;
  let stepTitle;
  let BottomContentToRender;
  if (currentStep === 1) {
    StepToRender = (
      <Step1
        bionId={bionId}
        data={step1.data}
        onSubmit={step1SubmitHandler}
        onValidityChange={step1ValidityChangeHandler}
      />
    );

    stepTitle = "Let's get started";
    BottomContentToRender = (
      <button
        type="submit"
        form="form-1"
        disabled={!step1.isValid}
        className={`${!step1.isValid ? styles["disabled"] : ""}`}
      >
        Create Account
      </button>
    );
  } else if (currentStep === 2) {
    StepToRender = <Step2 bionId={bionId} onSubmit={step2SubmitHandler} />;
    stepTitle = "Confirm Your Location";
    BottomContentToRender = (
      <button
        disabled
        className={`${
          !signupUserReqIsLoading || !signupUserWithoutBionIsLoading
            ? styles["disabled"]
            : ""
        }`}
      >
        {!signupUserReqIsLoading
          ? "Approach BION within 20 meter"
          : "Sending Request..."}
      </button>
    );
  } else if (currentStep === 3) {
    StepToRender = (
      <Step3
        userName={newUser?.userName || newUserWithoutBion?.userName}
        message={newUser?.message || ""}
        onSubmit={step3SubmitHandler}
      />
    );
    stepTitle = "Verify Your Account";
    BottomContentToRender = (
      <button
        disabled={!confirmUserAccountReqIsLoading}
        className={`${
          !confirmUserAccountReqIsLoading ? styles["disabled"] : ""
        }`}
      >
        {!confirmUserAccountReqIsLoading
          ? "Confirm Your Account"
          : "Confirming..."}
      </button>
    );
  } else if (currentStep === 4) {
    StepToRender = <Step4 message={newUser.message} />;
    stepTitle = "Account Approval";
    BottomContentToRender = (
      <button disabled>Waiting for BION Owner's Approval...</button>
    );
  }

  return (
    <div className={styles["page"]}>
      <div className={styles["right-side__container"]}>
        <div className={styles["gradient-animation__container"]}>
          <GradientAnimation
          // overlayStyles={{
          // background: "rgba(16, 16, 16, 0.5)",
          // backdropFilter: " blur(7px)",
          // }}
          />
        </div>
        <img className={styles["logo"]} src={logoImg} alt="Cultzyme" />
      </div>
      <div className={styles["container"]}>
        {/* Main Navigation */}
        <nav className={styles["main-navigation"]}>
          <button>
            <LeftArrowIcon />
          </button>
          <h1>Sign up</h1>
          <h2>{stepTitle}</h2>
        </nav>
        {/* End of Main Navigation */}

        {/* Signup {Step} */}
        {StepToRender}

        {/* Fixed bottom Section */}
        <div className={styles["bottom-fixed__container"]}>
          <div className={styles["button__container"]}>
            {BottomContentToRender}
          </div>

          <ProgressBar
            bionId={bionId}
            currentStep={currentStep > 3 ? 3 : currentStep}
          />
        </div>
      </div>
      {/* End of Fixed bottom Section */}
    </div>
  );
};

export default Signup;
