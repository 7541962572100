import * as yup from "yup";

const signupHalfSchema = yup.object({
  alias: yup
    .string()
    .required("Alias is a required field")
    .max(36, "Alias must be at most 36 characters")
    .matches(/^[a-zA-Z0-9]+$/, "Use only letters and numbers for the username"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Email must be a valid email"),
  userName: yup
    .string()
    .required("Username is a required field")
    .max(36, "Username must be 36 characters or less")
    .matches(/^[a-z0-9]+$/, "Use only lowercase letters and numbers"),
  countryCode: yup.string().required("Please select your dial code"),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .matches(/^[0-9]{1,15}$/, "Invalid phone number"),
  password: yup
    .string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
      "Password must include lowercase, uppercase, number, and special character"
    ),
});

export default signupHalfSchema;
