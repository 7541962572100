import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useGetActuatorDataQuery } from "../../features/actuator/actuatorApiSlice";
import { useGetSensorDataForProcessQuery } from "../../features/sensor/sensorApiSlice";

import styles from "./Process.module.css";

const Process = () => {
  const chartRef = useRef(null);

  const { processId } = useParams();

  const { data = [] } = useGetActuatorDataQuery(processId);
  const { data: sensorsData = [] } = useGetSensorDataForProcessQuery(processId);

  // Extract data from sensorsData and format it for Highcharts
  const formattedSensorsData = sensorsData.map((sensor) => {
    return {
      name: sensor.name,
      data: sensor.data.map((point) => [
        new Date(point[0]).getTime(), // DateTime Stamp
        // new Date(point[0]), // DateTime Stamp
        point[1], // Sensor value
      ]),
    };
  });

  const formattedData = data.length
    ? data.map((actuator) => {
        return {
          name: actuator?.name,
          data: actuator?.data.map((point) => [
            new Date(point[0]).getTime(),
            point[1],
          ]),
        };
      })
    : [];

  const options = {
    chart: {
      type: "spline",
      backgroundColor: "#101010",
      zoomType: "x",
      height: "500",
    },
    title: {
      text: "Dynamic Actuator Profile",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    subtitle: {
      text: "Monitoring Bioreactor Actuators",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      // startOnTick: true,
      labels: {
        enabled: true,
        format: "{value:%d/%m/%y %H:%M}",
        rotation: 20,
        style: { color: "#fff" },
        // align: "center",
      },
      title: {
        text: "Date",
        style: {
          color: "#fff",
        },
      },
      // tickInterval: 60 * 1000,
    },
    yAxis: {
      title: {
        text: "Values",
        style: {
          color: "#fff",
        },
      },
      labels: {
        style: { color: "#fff" },
      },
      min: 0,
      // max: 100,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: "<p>{point.x:%e. %b}: {point.y:.2f}</p>",
      // shared: true,
      // crosshairs: true,
    },
    legend: { itemStyle: { color: "#fcfcfc", opacity: 1 } },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          fillColor: "#FFFFFF",
          enabled: true,
          radius: 2.5,
          lineWidth: 1,
          lineColor: null,
        },
      },
    },

    colors: ["#00FFFF", "#39FF14", "#800080", "#DC143C", "#FFD700", "#40E0D0"],

    // series: [...formattedData],
  };

  console.log(formattedSensorsData);

  return (
    <div className={styles["page"]}>
      <div className={styles["charts__container"]}>
        <h3>Actuators Data</h3>
        <div className={styles["chart__container"]}>
          {formattedData.length &&
            formattedData.map((sensorData, index) => (
              <div
                key={sensorData.name}
                className={styles["chart__single__container"]}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...options,
                    title: {
                      text: `${sensorData.name}`,
                      align: "left",
                      style: {
                        color: "#fff",
                      },
                    },
                    yAxis: {
                      title: {
                        text: "Values",
                        style: {
                          color: "#fff",
                        },
                      },
                      labels: {
                        style: { color: "#fff" },
                      },
                      min: 0,
                      max:
                        sensorData.name === "Light Actuator" ||
                        sensorData.name === "Stir Actuator"
                          ? 250
                          : sensorData.name === "Temperature Actuator"
                          ? 40
                          : sensorData.name === "UltraSound Actuator" ||
                            sensorData.name === "Vacuum Actuator" ||
                            sensorData.name === "UltraViolet Actuator"
                          ? 1
                          : 100,
                      tickInterval:
                        sensorData.name === "UltraSound Actuator" ||
                        sensorData.name === "Vacuum Actuator" ||
                        sensorData.name === "UltraViolet Actuator"
                          ? 1
                          : undefined,
                      // sensorData.name === "Light Actuator" || "Stir Actuator"
                      //   ? 250
                      //   : 100,
                      // : sensorData.name === "Temperature Actuator"
                      // ? 40
                      // : 100,
                      // tickInterval: sensorData.name === "Acidity" ? 2 : undefined,
                    },
                    legend: { enabled: false },
                    series: [
                      {
                        name: sensorData.name,
                        data: sensorData.data,
                      },
                    ],
                  }}
                />
              </div>
            ))}
        </div>
      </div>

      <div className={styles["charts__container"]}>
        <h3>Sensors Data</h3>
        <div className={styles["chart__container"]}>
          {formattedSensorsData.length &&
            formattedSensorsData.map((sensorData, index) => (
              <div
                key={sensorData.name}
                className={styles["chart__single__container"]}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...options,
                    title: {
                      text: `${sensorData.name}`,
                      align: "left",
                      style: {
                        color: "#fff",
                      },
                    },
                    yAxis: {
                      title: {
                        text: "Values",
                        style: {
                          color: "#fff",
                        },
                      },
                      labels: {
                        style: { color: "#fff" },
                      },
                      min: 0,
                      max:
                        sensorData.name === "Light Actuator" ||
                        sensorData.name === "Stir Actuator"
                          ? 250
                          : sensorData.name === "Temperature Actuator"
                          ? 40
                          : sensorData.name === "UltraSound Actuator" ||
                            sensorData.name === "Vacuum Actuator" ||
                            sensorData.name === "UltraViolet Actuator"
                          ? 1
                          : 100,
                      tickInterval:
                        sensorData.name === "UltraSound Actuator" ||
                        sensorData.name === "Vacuum Actuator" ||
                        sensorData.name === "UltraViolet Actuator"
                          ? 1
                          : undefined,
                      // sensorData.name === "Light Actuator" || "Stir Actuator"
                      //   ? 250
                      //   : 100,
                      // : sensorData.name === "Temperature Actuator"
                      // ? 40
                      // : 100,
                      // tickInterval: sensorData.name === "Acidity" ? 2 : undefined,
                    },
                    legend: { enabled: false },
                    colors: [
                      "#39FF14",
                      "#800080",
                      "#DC143C",
                      "#FFD700",
                      "#40E0D0",
                    ],
                    series: [
                      {
                        name: sensorData.name,
                        data: sensorData.data,
                      },
                    ],
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Process;
