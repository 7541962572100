import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { AiOutlineLeft as LeftArrowIcon } from "react-icons/ai";

import CustomInput from "../../components/Signup/Input/CustomInput";

import loginSchema from "../../validations/login";

import logoImg from "../../assets/images/logo.png";

import { useSigninUserMutation } from "../../features/auth/authApiSlice";
import { setCredentials } from "../../features/auth/authSlice";

import styles from "./Login.module.css";
import GradientAnimation from "../../components/UI/GradientAnimation/GradientAnimation";

const Login = () => {
  // Initialize useForm hook to handle form state and validation
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Capture Referrer URL:
  // Extract the URL that the user was trying to access before landing on the login page.
  // If no referrer information is available, default to the home ("/") page.
  const { state } = useLocation();
  const referrerUrl = state?.from
    ? `${state.from.pathname}${state.from.search}`
    : "/";

  const [signinUserReq, { isLoading: signinUserReqIsLoading }] =
    useSigninUserMutation();

  // Handler for submiting a login request
  const loginSubmitHandler = async (data) => {
    try {
      const signinUserResData = await signinUserReq({
        usernameOrEmail: data.usernameOrEmail,
        password: data.password,
      }).unwrap();

      dispatch(setCredentials(signinUserResData));
      navigate(referrerUrl, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles["page"]}>
      <div className={styles["right-side__container"]}>
        <div className={styles["gradient-animation__container"]}>
          <GradientAnimation
          // overlayStyles={{
          // background: "rgba(16, 16, 16, 0.5)",
          // backdropFilter: " blur(7px)",
          // }}
          />
        </div>
        <img className={styles["logo"]} src={logoImg} alt="Cultzyme" />
      </div>
      <div className={styles["container"]}>
        {/* Main Navigation */}
        <nav className={styles["main-navigation"]}>
          <button>
            <LeftArrowIcon />
          </button>
          <h1>Log In</h1>
          <h2>Welcome back!</h2>
        </nav>
        {/* End of Main Navigation */}

        {/* Login */}
        <div className={styles["step"]}>
          <h3 className={styles["step-info"]}>
            Unlock BION's potential. Log in to control your intelligent
            bioreactor companion.
          </h3>
          <form
            id="login-form"
            onSubmit={handleSubmit(loginSubmitHandler)}
            className={styles["login-form"]}
          >
            <CustomInput
              label="Email or Username"
              id="usernameOrEmail"
              register={register}
              type="text"
              placeholder="Use email or username"
              autoComplete="off"
            />

            <CustomInput
              label="Password"
              id="password"
              register={register}
              type="password"
              placeholder="Enter your password"
              autoComplete="off"
            />
          </form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link
              to="/signup"
              style={{
                color: "#22556a",
                display: "block",
                width: "fit-content",
              }}
            >
              Signup
            </Link>
            <Link
              to="/forget-password"
              style={{
                color: "#22556a",
                display: "block",
                width: "fit-content",
              }}
            >
              Forget Password?
            </Link>
          </div>
        </div>

        {/* Fixed bottom Section */}
        <div className={styles["bottom-fixed__container"]}>
          <div className={styles["button__container"]}>
            <button
              form="login-form"
              disabled={!isValid || signinUserReqIsLoading}
              className={!isValid ? styles["disabled"] : ""}
            >
              {signinUserReqIsLoading ? "Loading..." : "Log in"}
            </button>
          </div>
        </div>
        {/* End of Fixed bottom Section */}
      </div>
    </div>
  );
};

export default Login;
