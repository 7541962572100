import React, { useState, useEffect, useRef } from "react";
import styles from "./InputBox.module.css";

const InputBox = ({ onSendMessage, chatIsPrinting }) => {
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px"; // Reset height to shrink if text is deleted
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [message]);

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className={styles.inputBox}>
      <textarea
        ref={textareaRef}
        className={styles.inputField}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message here..."
        rows={1} // Start as a single line
      />
      <button
        onClick={handleSend}
        className={`${styles.sendButton} ${
          chatIsPrinting ? styles["disabled"] : ""
        }`}
        disabled={chatIsPrinting}
      >
        Send
      </button>
    </div>
  );
};

export default InputBox;
// import React, { useState } from "react";
// import styles from "./InputBox.module.css";

// const InputBox = ({ onSendMessage }) => {
//   const [message, setMessage] = useState("");

//   const handleSend = () => {
//     if (message.trim()) {
//       onSendMessage(message);
//       setMessage("");
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault();
//       handleSend();
//     }
//   };

//   return (
//     <div className={styles.inputBox}>
//       <textarea
//         className={styles.inputField}
//         value={message}
//         onChange={(e) => setMessage(e.target.value)}
//         onKeyPress={handleKeyPress}
//         placeholder="Type your message here..."
//       />
//       <button onClick={handleSend} className={styles.sendButton}>
//         Send
//       </button>
//     </div>
//   );
// };

// export default InputBox;
