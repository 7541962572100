import { AnimatePresence, motion } from "framer-motion";
import styles from "./Settings.module.css";

import { AiOutlineEdit as EditIcon } from "react-icons/ai";

import { useGetUserDataQuery } from "../../features/user/userApiSlice";

import { useState, useRef } from "react";

import ModalWrapper from "../../components/Wrappers/ModalWrapper/ModalWrapper";

// import { useModifyProcessDataMutation } from "../../../../features/process/processApiSlice";
import { useUpdateUserDataMutation } from "../../features/user/userApiSlice";

const UpdateData = ({ onClose }) => {
  const inputRef = useRef();

  const [req] = useUpdateUserDataMutation();

  // const [modifyProcessDataReq] = useModifyProcessDataMutation();

  const saveDataHandler = async () => {
    await req({
      alias: inputRef.current.value,
    });
    onClose();
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["finish-process__modal"]}>
        <h4>Update Alias</h4>

        <div style={{ marginBottom: "12px" }}>
          <label htmlFor="processName">Alias:</label>
          <input
            ref={inputRef}
            name="alias"
            id="Alias"
            type="text"
            // defaultValue={process.processName}
          />
        </div>

        <div className={styles["action-btns"]}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={onClose}
          >
            Cancel
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={saveDataHandler}
          >
            Save
          </motion.button>
        </div>
      </div>
    </ModalWrapper>
  );
};

const Settings = () => {
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const { data: userData } = useGetUserDataQuery();

  const onUpdateOpen = () => {
    setUpdateIsOpen(true);
  };

  const onUpdateClose = () => {
    setUpdateIsOpen(false);
  };

  return (
    <>
      <AnimatePresence>
        {updateIsOpen && <UpdateData onClose={onUpdateClose} />}
      </AnimatePresence>
      <div className={styles["page"]}>
        <header>
          <h1 className={styles["title"]}>Settings</h1>
          <p className={styles["subtitle"]}>
            Welcome to the BIONS Dashboard, your central hub for managing and
            monitoring your connected bioreactors. This page provides you with
            an overview of all the BIONS you are currently bound to, allowing
            you to seamlessly inspect, handle, and configure each machine. Stay
            informed about the status and performance of your bioreactors,
            empowering you to optimize their functionality and ensure smooth
            operations. Explore the details of each BION effortlessly, putting
            you in control of your bioprocessing environment.
          </p>
        </header>

        <div className={styles["main-container"]}>
          <p>
            Username: <span>{userData?.userName}</span>
          </p>
          <div className={styles["edit__container"]}>
            <p>
              Alias: <span>{userData?.alias}</span>
            </p>
            <button onClick={onUpdateOpen}>
              <EditIcon />
            </button>
          </div>
          <p>Email: {userData?.email}</p>
        </div>
      </div>
    </>
  );
};

export default Settings;
