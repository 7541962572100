import { useGetReadyBionsQuery } from "../../features/bion/bionApiSlice";

import { CgAddR as PlusIcon } from "react-icons/cg";

import styles from "./Bions.module.css";
import BionCard from "../../components/Bions/BionCard/BionCard";

import BionImg from "../../assets/images/bion.png";

const Bions = () => {
  const { data: readyBions } = useGetReadyBionsQuery();
  console.log(readyBions);

  return (
    <div className={styles["page"]}>
      <header>
        <h1 className={styles["title"]}>
          <img src={BionImg} alt="" />
          BIONS
        </h1>
        {/* <p className={styles["subtitle"]}>
          Welcome to the BIONS Dashboard, your central hub for managing and
          monitoring your connected bioreactors. This page provides you with an
          overview of all the BIONS you are currently bound to, allowing you to
          seamlessly inspect, handle, and configure each machine. Stay informed
          about the status and performance of your bioreactors, empowering you
          to optimize their functionality and ensure smooth operations. Explore
          the details of each BION effortlessly, putting you in control of your
          bioprocessing environment.
        </p> */}
      </header>

      <div className={styles["row"]}>
        <ul className={styles["general-info"]}>
          <li>
            Linked Bions: <span>{readyBions && readyBions.length}</span>
          </li>
          <li>
            Running process:{" "}
            <span>
              {readyBions &&
                readyBions.reduce((acc, bion) => {
                  if (
                    bion.bionMode === "PROCESS_ACTIVE" ||
                    bion.bionMode === "REPLICATION_ACTIVE"
                  ) {
                    return acc + 1;
                  }
                  return acc;
                }, 0)}
            </span>
          </li>
          <li>
            Running NORMAL process:{" "}
            <span>
              {readyBions &&
                readyBions.reduce((acc, bion) => {
                  if (bion.bionMode === "PROCESS_ACTIVE") {
                    return acc + 1;
                  }

                  return acc;
                }, 0)}
            </span>
          </li>
          <li>
            Running REPLICATION process:{" "}
            <span>
              {readyBions &&
                readyBions.reduce((acc, bion) => {
                  if (bion.bionMode === "REPLICATE_ACTIVE") {
                    return acc + 1;
                  }

                  return acc;
                }, 0)}
            </span>
          </li>
        </ul>

        {/* <button className={styles["add-bion"]}>
          <PlusIcon />
        </button> */}
      </div>

      <div className={styles["bions__container"]}>
        {readyBions &&
          readyBions.map((bion) => <BionCard key={bion.bionId} bion={bion} />)}
      </div>
    </div>
  );
};

export default Bions;
