import { useState } from "react";
import MessageList from "../MessageList/MessageList";
import InputBox from "../InputBox/InputBox";
import { useSelector } from "react-redux";
import styles from "./ChatContainer.module.css";
import { selectCurrentToken } from "../../../features/auth/authSlice";

const ChatContainer = () => {
  const [messages, setMessages] = useState([]);
  const [chatIsPrinting, setChatIsPrinting] = useState(false);
  const sessionToken = useSelector(selectCurrentToken);

  const ensureSpaceAfterPeriod = (text) => {
    return text.replace(/\.([^\s.])/g, ". $1");
  };

  const sendMessage = async (msg) => {
    console.log("Sending message:", msg);
    setChatIsPrinting(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length, text: msg, sender: "user" },
    ]);

    const assistantMessageId = new Date().getTime();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${sessionToken}`,
            accept: "text/event-stream",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: msg }),
        }
      );

      if (!response.ok) {
        const JSONResponse = await response.json();
        console.log(JSONResponse.message);
        throw new Error(JSONResponse.message);
      }

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let isFirstChunk = true;
        let completeMessage = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            console.log("Stream ended");
            setChatIsPrinting(false);
            break;
          }
          let chunkText = decoder.decode(value, { stream: true });

          let processedText = chunkText
            .split("\n")
            .filter((line) => line.startsWith("data:"))
            .map((line) => line.replace(/^data:/, ""))
            .join("");

          completeMessage += processedText;

          // Apply space after period correction
          completeMessage = ensureSpaceAfterPeriod(completeMessage);

          if (isFirstChunk) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                id: assistantMessageId,
                text: completeMessage,
                sender: "assistant",
              },
            ]);
            isFirstChunk = false;
          } else {
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.id === assistantMessageId
                  ? {
                      ...msg,
                      text: ensureSpaceAfterPeriod(msg.text + processedText),
                    }
                  : msg
              )
            );
          }
        }
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: new Date().getTime(), // Generate a unique ID for the error message
          text: error?.message,
          sender: "assistant",
          isError: true, // Set isError to true for error messages
        },
      ]);
      setChatIsPrinting(false); // Ensure chat is no longer printing
    }
  };

  return (
    <div className={styles.chatContainer}>
      {/* <MessageList messages={messages} /> */}
      <MessageList
        messages={messages.map((message) => {
          return message.sender === "assistant"
            ? {
                ...message,
                text: (
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                ),
              }
            : message;
        })}
      />
      <InputBox onSendMessage={sendMessage} chatIsPrinting={chatIsPrinting} />
    </div>
  );
};

export default ChatContainer;
