import { Link } from "react-router-dom";
import styles from "./Home.module.css";

import bionDashboardImg from "../../assets/images/bion-dashboard.png";
import aiImg from "../../assets/images/ai.png";
import storeImg from "../../assets/images/store.png";

// import { FaRobot as AiIcon } from "react-icons/fa";
import { RiTeamFill as CommunityIcon } from "react-icons/ri";
// import { FaStore as StoreIcon } from "react-icons/fa";
import { BsPlugin as PluginIcon } from "react-icons/bs";
import { TbStairs as ScaleIcon } from "react-icons/tb";
// import { TbRectangleVertical as BionIcon } from "react-icons/tb";
// import { RiAddCircleLine as AddIcon } from "react-icons/ri";

const Home = () => {
  // appData.js
  const appData = [
    {
      title: "BIONS Dashboard",
      creator: "Cultzyme",
      description:
        "Access and configure your BIONS while running a bioprocess.",
      link: "/bions",
      badgeText: "BIONS",
      badgeColor: "#2e95d3",
      icon: <img width={80} src={bionDashboardImg} alt="" />,
    },
    {
      // title: "AI Data Management",
      title: "In Silico",
      creator: "Cultzyme",
      description:
        "Train your AI for bioprocess specific tasks by providing data.",
      link: "/cultzyme-ai",
      badgeText: "AI - Quantum",
      badgeColor: "#e91e63",
      icon: <img width={90} src={aiImg} alt="" />,
      // icon: <AiIcon />,
    },
    {
      title: "Community",
      creator: "Cultzyme",
      description:
        "Connect with other experts, share knowledge, and collaborate.",
      link: "/home",
      badgeText: "Community",
      badgeColor: "#9c27b0",
      icon: <CommunityIcon />,
      // underConstruction: true,
    },
    {
      title: "CULTZYME Store",
      creator: "Cultzyme",
      description:
        "Explore and purchase enzymes tailored for your bioprocess needs.",
      link: "/home",
      badgeText: "Store",
      badgeColor: "#ff9800",
      icon: <img width={90} src={storeImg} alt="" />,
      // icon: <StoreIcon />,
    },
    {
      title: "Plugin Store",
      creator: "Cultzyme",
      description:
        "Find and install plugins to extend the capabilities of your applications.",
      link: "/home",
      badgeText: "Plugins",
      badgeColor: "#00bcd4",
      icon: <PluginIcon />,
    },
    {
      title: "Scale Up",
      creator: "Cultzyme",
      description:
        "Tools and guidance for scaling your bioprocesses efficiently.",
      link: "/home",
      badgeText: "Scale Up",
      badgeColor: "#4caf50",
      icon: <ScaleIcon />,
    },
  ];
  return (
    <div className={styles["app-wrapper"]}>
      <div className={styles["homePage"]}>
        {appData.map((app, index) => (
          <Link to={app.link} key={app.title} className={styles["subAppCard"]}>
            <div
              className={styles["iconContainer"]}
              style={{
                color: app.badgeColor,
                fontSize: "90px",
                width: "max-content",
                margin: "0 auto 1rem",
              }}
            >
              <div className={styles["white-bg"]}></div>
              {app.icon}
            </div>
            <div className={styles["textContainer"]}>
              <div className={styles["title-container"]}>
                <h3>{app.title}</h3>
                {app.badgeText && (
                  <div
                    className={styles["badge"]}
                    style={{ backgroundColor: app.badgeColor }}
                  >
                    {app.badgeText}
                  </div>
                )}
              </div>
              <p className={styles["creator"]}>By {app.creator}</p>
              <p>{app.description}</p>
            </div>
          </Link>
        ))}
        {/* <button className={`${styles["subAppCard"]} ${styles["special"]}`}>
          <div></div>
          <p>
            <AddIcon />
          </p>
        </button> */}
      </div>
    </div>
  );
};

export default Home;
