import { Link } from "react-router-dom";

import Timer from "../Timer/Timer";

import {
  useDisconnectUserFromBionMutation,
  usePutBionToSteriliseModeMutation,
} from "../../../features/bion/bionApiSlice";

import styles from "./BionCard.module.css";

import {
  HiStatusOnline as OnlineIcon,
  HiStatusOffline as OfflineIcon,
} from "react-icons/hi";
import { MdCleaningServices as SteriliseIcon } from "react-icons/md";
import { VscDebugDisconnect as DisconnectIcon } from "react-icons/vsc";
import { IoEnter as EnterIcon } from "react-icons/io5";
import { RiForbid2Fill as ForbiddenIcon } from "react-icons/ri";
import Timer2 from "../Timer/Timer2";

const BionCard = ({ bion }) => {
  const [disconnectUserFromBionReq] = useDisconnectUserFromBionMutation();
  const [putBionToSteriliseModeReq] = usePutBionToSteriliseModeMutation();

  const disconnectFromBionHandler = () => {
    disconnectUserFromBionReq(bion.bionId);
  };

  const putBionToSteriliseModeHandler = () => {
    putBionToSteriliseModeReq({
      bionId: bion.bionId,
      steriliseActive: bion.bionMode === "STERILISE_ACTIVE" ? false : true,
    });
  };

  let ContentToRender;

  if (bion.isOn) {
    switch (true) {
      case bion.bionMode === "PROCESS_ACTIVE":
        // Display content for normal process
        ContentToRender = (
          <div className={styles["info"]}>
            <h4>Active Process: Normal Mode</h4>
            <p>
              Your Bion is currently executing a <span>normal process</span> .
              While it's in progress, you have the option to access your Bion
              and configure or inspect the process details.
            </p>
          </div>
        );
        break;
      case bion.bionMode === "REPLICATE_ACTIVE":
        // Display content for replication process
        ContentToRender = (
          <div className={styles["info"]}>
            <h4>Active Process: Replication Mode</h4>
            <p>
              Your Bion is currently undergoing a{" "}
              <span>replication process</span>. During this process, you can
              access your Bion to monitor the replication progress, terminate it
              if needed, or check the remaining time for completion.
            </p>
          </div>
        );
        break;
      case bion.bionMode === "STERILISE_ACTIVE":
        // Display content for Sterilising
        const date = new Date(bion.sterilizationDate);
        date.setMinutes(date.getMinutes() + 30);
        // date.setMinutes(date.getMinutes() + 30);

        ContentToRender = (
          <div
            className={`${!bion.isLidSet ? styles["hide"] : ""} ${
              styles["info"]
            }`}
          >
            <h4>Sterilising...</h4>
            <Timer2 shutDownDate={date.getTime()} freeze={!bion.isLidSet} />
          </div>
        );
        break;
      default:
        // Display content when no active process is running
        ContentToRender = (
          <div className={styles["info"]}>
            <h4>No active process running.</h4>
            <p>
              You have the <span>opportunity</span> to initiate a{" "}
              <span>new process</span> on this Bion. Get started now!
            </p>
          </div>
        );
    }
  } else {
    // Display content when the bion is turned off
    ContentToRender = (
      <div className={styles["info"]}>
        <p>
          We're <span>actively working</span> to reconnect your bioreactor. If
          it doesn't regain connection <span>within 10 minutes</span>, you'll be{" "}
          <span>automatically disconnected</span>. Thank you for your patience.
        </p>
        <Timer shutDownDate={bion.shutDownDate} />
      </div>
    );
  }

  return (
    <div className={styles["bion"]}>
      {/* Bion Name */}
      <h3 className={styles["bion-name"]}>{bion.bionName}</h3>
      <h5 className={styles["bion-lid"]}>
        Lid State:{" "}
        {bion.isLidSet ? (
          <span style={{ color: "rgb(70, 196, 70)" }}>Closed</span>
        ) : (
          <span style={{ color: "rgb(232, 67, 67)" }}>Opened</span>
        )}
      </h5>
      {/* Bion Status */}
      <p
        className={`${styles["bion-status"]} ${
          styles[bion.isOn ? "online" : "offline"]
        }`}
      >
        {bion.isOn ? (
          <>
            <OnlineIcon /> Online
          </>
        ) : (
          <>
            <OfflineIcon /> Offline
          </>
        )}
      </p>

      {/* Bion Content */}
      {ContentToRender}

      {/* Bottom Container */}
      {bion?.isOn && (
        <div
          className={`${styles["bottom__outer-container"]} ${
            bion?.bionMode === "STERILISE_ACTIVE" ||
            bion?.bionMode === null ||
            !bion?.bionMode
              ? styles["visible"]
              : ""
          }`}
        >
          <Link
            to={
              bion.bionMode === "STERILISE_ACTIVE"
                ? "#"
                : `/bions/${bion.bionId}`
            }
          >
            <span>
              <EnterIcon />
            </span>
            Enter Bion
            {bion.bionMode === "STERILISE_ACTIVE" && (
              <span className={styles["forbid"]}>
                <ForbiddenIcon />
              </span>
            )}
          </Link>
          <button
            onClick={putBionToSteriliseModeHandler}
            className={
              bion.bionMode !== "STERILISE_ACTIVE" &&
              bion.bionMode !== null &&
              bion?.bionMode
                ? styles["hidden"]
                : ""
            }
          >
            <span>
              <SteriliseIcon />
            </span>
            Sterilise
            <span
              className={
                bion.bionMode === "STERILISE_ACTIVE"
                  ? styles["on"]
                  : styles["off"]
              }
            >
              {bion.bionMode === "STERILISE_ACTIVE" ? "ON" : "OFF"}
            </span>
          </button>
          <button
            onClick={disconnectFromBionHandler}
            disabled={bion.bionMode === "STERILISE_ACTIVE"}
          >
            <span>
              <DisconnectIcon />{" "}
            </span>
            Disconnect
            {bion.bionMode === "STERILISE_ACTIVE" && (
              <span className={styles["forbid"]}>
                <ForbiddenIcon />
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default BionCard;
